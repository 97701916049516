import axios from 'axios';

interface LoggerService {
    url: string;
    payload: ErrorPayload
}

interface ErrorPayload {
    mid: string;
    action: string;
    errorStack: string;
    message: string;
    level: string;
    errorTime: string;
}

export const loggerService = async({url, payload}: LoggerService): Promise<void> => {
    try {
        await axios.post(url, payload);   
    }catch(error: any) {
        console.error(error?.message);
    }
}