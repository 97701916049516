/* eslint-disable no-restricted-globals */
import React from "react";
import {ErrorBoundary} from 'react-error-boundary';
import { loggerService } from "./util/loggerService";

import {
  // initPlasmicLoader,
  PlasmicRootProvider,
  PlasmicComponent,
  ComponentRenderData
} from '@plasmicapp/loader-react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PLASMIC } from './plasmic-init';

interface ErrorPayload {
    mid: string;
    action: string;
    errorStack: string;
    message: string;
    level: string;
    errorTime: string;
}

function App() {

  const handleError = (error: Error, info: {componentStack: string}) => {

    const payload: ErrorPayload = {
      mid: 'root1 ',
      message: error?.message,
      action: error?.stack as string,
      errorStack: info?.componentStack,
      level: error?.name,
      errorTime: new Date().toISOString()
    }

    loggerService({url: '/logs', payload})
  }
  return (
    <ErrorBoundary onError={handleError} FallbackComponent={ErrorFallBackComponent}>
    <PlasmicRootProvider loader={PLASMIC} >
      <Router>
        <Routes>
          <Route path="/*" element={CatchAllPage()} />
        </Routes>
      </Router>
    </PlasmicRootProvider>
    </ErrorBoundary>
  );
}

interface ErrorFallBack {
  error: Error,
  resetErrorBoundary: () => void,
}

function ErrorFallBackComponent({error, resetErrorBoundary}: ErrorFallBack) {
  return (
    <div>
      <p>Somehting went wrong</p>
      <pre>{error?.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

// We try loading the Plasmic page for the current route.
// If it doesn't exist, then return "Not found."
export function CatchAllPage() {
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState<ComponentRenderData | null>(null);

  useEffect(() => {
    async function load() {
      
      const pageData = await PLASMIC.maybeFetchComponentData(location.pathname);
      setPageData(pageData);
      setLoading(false);
    }
    load();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (!pageData) {
    return <div>Not found</div>;
  }
  // The page will already be cached from the `load` call above.
  
  return <PlasmicComponent component={location.pathname} />;
}
export default App;